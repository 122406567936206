import React from "react"

const Email = ({ size, color, className }) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${size} h-${size} stroke-current text-${color} ${
      className || ""
    }`}
  >
    <rect x="5.5" y="11.5" width="37" height="25" rx="4.5" />
    <path d="M7 35L18 24M41 35L30 24" />
    <path d="M41 13L28.2426 25.7574C25.8995 28.1005 22.1005 28.1005 19.7574 25.7574L7 13" />
  </svg>
)

export default Email
