import React, { forwardRef } from "react"

const Layout = forwardRef(
  (
    {
      id,
      children,
      container,
      background,
      gradient,
      screen,
      className,
      styles,
    },
    ref
  ) => (
    <section
      id={id}
      ref={ref}
      className={`w-full ${background ? "bg-" + background : ""} ${
        gradient
          ? `bg-gradient-to-b from-${gradient?.from} to-${gradient?.to}`
          : ""
      } ${screen ? "h-screen" : ""} ${className ? className : ""}`}
      style={{ marginBottom: "-1px", ...styles }}
    >
      {container ? (
        <div className="container-fluid ">{children}</div>
      ) : (
        children
      )}
    </section>
  )
)

Layout.displayName = "Layout"

export default Layout
