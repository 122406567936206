import React from "react"

const Phone = ({ size, color, className }) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${size} h-${size} stroke-current text-${color} ${
      className || ""
    }`}
  >
    <rect
      x="12.5"
      y="44.4961"
      width="41"
      height="25"
      rx="3.5"
      transform="rotate(-90 12.5 44.4961)"
    />
    <rect x="13" y="8.99609" width="24" height="1" />
    <rect x="23" y="5.99609" width="4" height="1" rx="0.5" />
    <rect x="24" y="39.9961" width="2" height="2" rx="1" />
    <rect x="13" y="36.9961" width="24" height="1" />
  </svg>
)

export default Phone
