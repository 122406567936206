import { randRange } from "../../utils/math"
import React, { useState, useRef, useCallback, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const Blink = ({ size, className }) => {
  const [isBlinked, setBlink] = useState(false)
  const eyeRef = useRef(null)
  const blinkRef = useRef(null)
  const timeoutId = useRef(null)

  const cancel = useCallback(() => clearTimeout(timeoutId.current), [])

  const onMouseEnter = () => {
    cancel()
    if (!isBlinked) {
      setBlink(true)
    }
  }

  const onMouseLeave = () => {
    const nextTickAt = randRange(500, 2000)
    timeoutId.current = setTimeout(() => {
      setBlink(false)
    }, nextTickAt)
  }

  useEffect(() => {
    const trigger = ScrollTrigger.create({
      trigger: "#footer",
      onEnter: () => {
        setBlink(true)
        const nextTickAt = randRange(500, 2000)
        timeoutId.current = setTimeout(() => {
          setBlink(false)
        }, nextTickAt)
      },
    })

    return () => {
      cancel()
      trigger.kill()
    }
  }, [])

  return (
    <div
      onTouchStart={onMouseEnter}
      onTouchEnd={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <svg
        viewBox="0 0 96 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`w-${size} h-auto ${className || ""}`}
      >
        <path
          d="M11.5196 34L4.29296e-07 34C1.92193e-07 53.883 16.3321 70 36.4792 70L59.5196 70C79.6667 70 96 53.883 96 34L84.4804 34C84.4804 47.5823 73.2831 58.631 59.5208 58.631L36.4792 58.631C22.7169 58.6321 11.5196 47.5823 11.5196 34Z"
          fill="#2727FF"
        />
        <circle cx="31" cy="9" r="9" fill="white" />
        <circle
          ref={eyeRef}
          cx="65"
          cy="9"
          r="9"
          fill="white"
          opacity={isBlinked ? "0" : "1"}
        />
        <rect
          ref={blinkRef}
          opacity={isBlinked ? "1" : "0"}
          x="53"
          y="4"
          width="24"
          height="10"
          rx="5"
          fill="#2727FF"
        />
      </svg>
    </div>
  )
}

export default Blink
