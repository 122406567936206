import React from "react"

const Arrow = ({ size, color, className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${size} h-${size} stroke-current text-${color} ${
      className || ""
    }`}
  >
    <path d="M8 3L8 12.9999" />
    <path d="M5 10L8 13L11 10" strokeLinejoin="bevel" />
  </svg>
)

export default Arrow
