import React from "react"

const WhatsApp = ({ size, color, className }) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${size} h-${size} stroke-current text-${color} ${
      className || ""
    }`}
  >
    <path d="M13.5 11.1007L17.0355 14.6362L15.9749 15.6969C14.9986 16.6732 14.9986 18.2561 15.9749 19.2324L20.2175 23.475C21.1938 24.4513 22.7767 24.4513 23.753 23.475L24.8137 22.4144L28.3492 25.9499L27.2886 27.0106C25.1407 29.1584 21.6583 29.1584 19.5104 27.0106L12.4393 19.9395C10.2915 17.7916 10.2915 14.3092 12.4393 12.1613L13.5 11.1007Z" />
    <path d="M9.7372 36.4172L9.53797 36.2936L9.31556 36.3678L0.659262 39.2532L2.59555 29.5717L2.63088 29.3951L2.54543 29.2365C1.07681 26.511 0.500043 23.3732 0.500043 20C0.500043 9.23045 9.23048 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C16.2478 39.5 12.7312 38.274 9.7372 36.4172Z" />
  </svg>
)

export default WhatsApp
