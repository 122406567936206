import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { usePageContext } from "../providers/pageContext"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { lang } = usePageContext()

  const {
    defaultTitle,
    titleTemplate,
    descriptions,
    siteUrl,
    defaultImage,
    social,
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || descriptions[lang],
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    lang: lang || "pt",
  }

  return (
    <Helmet
      htmlAttributes={{ lang: seo.lang }}
      title={seo.title}
      titleTemplate={titleTemplate[lang]}
    >
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* Opengraph */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={seo.title} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={seo.url}></meta>
      {social.twittter && (
        <meta name="twitter:creator" content={social.twittter} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate {
          pt
          en
        }
        descriptions {
          pt
          en
        }
        siteUrl: url
        defaultImage: image
        social {
          twitter
        }
      }
    }
  }
`
