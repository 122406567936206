import { graphql, useStaticQuery } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import React, { useState, useEffect, useRef, useCallback } from "react"
import { useLanguageSwitch, usePageContext } from "../providers/pageContext"
import Icon from "./icon"

const LanguageSwitch = () => {
  const [open, setOpen] = useState(false)
  const [moveCircle, setMoveCircle] = useState(false)
  const { site } = useStaticQuery(query)
  const context = usePageContext()
  const switchLanguage = useLanguageSwitch()
  const ref = useRef()
  const breakpoints = useBreakpoint()

  const availableLanguages = site.siteMetadata.supportedLanguages.filter(
    lang => lang !== context.lang
  )

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const changeLanguage = lang => {
    setMoveCircle(true)
    setTimeout(() => {
      switchLanguage(lang)
    }, 150)
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleOutsideClick)
    } else {
      document.removeEventListener("mousedown", handleOutsideClick)
    }

    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [open])

  const getCurrentLangTextColor = useCallback(() => {
    if (!open) return "text-black"

    return moveCircle ? "text-black" : "text-white"
  }, [open, moveCircle])

  return (
    <div
      className="w-10 h-10 md:w-14 md:h-14 relative"
      onMouseLeave={() => breakpoints.lg && setOpen(false)}
      onMouseEnter={() => breakpoints.lg && setOpen(true)}
    >
      <div
        className={`absolute transition bg-blue rounded-full p-1 -bottom-1 md:bottom-0 right-0 ${
          open ? "opacity-0" : "opacity-100"
        }`}
        style={{ zIndex: 150 }}
      >
        <Icon type="arrow" size={2} color="white md:w-3 md:h-3" />
      </div>
      <div
        ref={ref}
        className={`transition-all absolute w-10 md:w-14 ${
          open ? "h-20 md:h-28" : "h-10 md:h-14"
        } p-1 bg-white rounded-full overflow-hidden`}
      >
        <div className="flex flex-col items-center justify-between relative">
          <div
            className={`transition-all transform w-8 h-8 md:w-12 md:h-12 bg-blue rounded-full absolute pointer-events-none ${
              open ? "opacity-100" : "opacity-0"
            } ${moveCircle ? "translate-y-10 md:translate-y-14" : ""}`}
            style={{
              zIndex: 100,
            }}
          />
          <button
            onClick={() => !breakpoints.lg && setOpen(!open)}
            className={`select-none font-base text-14 font-normal uppercase cursor-pointer user-select-none w-8 h-8 md:w-12 md:h-12 flex-none bg-transparent rounded-full flex justify-center items-center mb-2 ${getCurrentLangTextColor()}`}
            style={{ zIndex: 105 }}
          >
            {context.lang}
          </button>

          {availableLanguages.map(lang => (
            <button
              key={lang}
              className={`select-none font-base text-14 font-normal uppercase bg-transparent w-8 h-8 md:w-12 md:h-12 rounded-full flex-none ${
                moveCircle ? "text-white" : "text-black hover:bg-positive"
              }`}
              onClick={() => changeLanguage(lang)}
              style={{ zIndex: 105 }}
            >
              {lang}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LanguageSwitch

const query = graphql`
  {
    site {
      siteMetadata {
        supportedLanguages
      }
    }
  }
`
