import React from "react"

import Phone from "./Phone"
import Email from "./Email"
import WhatsApp from "./WhatsApp"
import Blink from "./Blink"
import Cross from "./Cross"
import Check from "./Check"
import Arrow from "./Arrow"

const Icon = ({ type, size, color, className, animate }) => {
  switch (type) {
    case "phone":
      return <Phone size={size} color={color} className={className} />
    case "email":
      return <Email size={size} color={color} className={className} />
    case "whatsapp":
      return <WhatsApp size={size} color={color} className={className} />
    case "blink":
      return <Blink size={size} className={className} animate={animate} />
    case "cross":
      return <Cross size={size} color={color} className={className} />
    case "check":
      return <Check size={size} color={color} className={className} />
    case "arrow":
      return <Arrow size={size} color={color} className={className} />
    default:
      return null
  }
}

export default Icon
