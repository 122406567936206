import React from "react"

const Cross = ({ size, color, className }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${size} h-${size} stroke-current text-${color} ${
      className || ""
    }`}
  >
    <path d="M17 1L1 17" strokeLinecap="round" />
    <path d="M1 1L17 17" strokeLinecap="round" />
  </svg>
)

export default Cross
